<template>
  <BaseListPage locale-section="pages.groups" route="group">
    <GroupsList />
  </BaseListPage>
</template>

<script>
export default {
  name: "GroupsPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    GroupsList: () => import("./GroupsList")
  }
};
</script>
